<template>
  <div class="viewer">
    <pdf
      v-for="i in numPages"
      :key="i"
      :src="resultUrl"
      :page="i"
      style="width: 100%;"
      @page-loaded="handlePageLoaded"
    />
    <div v-if="signature" class="contract-operation">
      <van-button class="operation-btn" block @click="handleSignature">去签名</van-button>
      <div class="split-line" />
      <van-button class="operation-btn" block @click="handleUpload">上传合同</van-button>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import { Button, Toast } from 'vant'

export default {
  name: 'PdfViewer',
  components: {
    pdf,
    VanButton: Button
  },
  data() {
    return {
      pdfUrl: '',
      initialPdfUrl: '',
      signatureUrl: '',
      numPages: 0,
      uni: null,
      signature: false
    }
  },
  created() {
    this.initSdk()
    this.loadPdfUrl()
    setTimeout(() => {
      this.uni.postMessage({
        data: {
          action: 'back'
        }
      })
    }, 2000)
  },
  activated() {
    const { pdfUrl, signatureUrl, initialPdfUrl } = this.$route.query
    if (pdfUrl !== this.pdfUrl || this.signatureUrl !== signatureUrl || initialPdfUrl !== this.initialPdfUrl) {
      this.loadPdfUrl()
    }
  },
  computed: {
    resultUrl() {
      return  pdf.createLoadingTask({
        url: this.pdfUrl,
        //引入pdf.js字体，templ
        cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/',
        cMapPacked: true
      })
    }
  },
  methods: {
    /**
     * 根据环境配置uniapp api
     */
    initSdk() {
      const userAgent = navigator.userAgent
      if (/miniProgram/i.test(userAgent) && /micromessenger/i.test(userAgent)) {
        this.uni = this.$wx.miniProgram
      } else {
        this.uni = this.$uni
      }
    },
    /**
     * 从路由获取pdfUrl
     */
    loadPdfUrl() {
      const { pdfUrl, signatureUrl, signature, initialPdfUrl } = this.$route.query
      this.pdfUrl = pdfUrl
      this.initialPdfUrl = initialPdfUrl
      if (this.pdfUrl && this.pdfUrl.length > 0) {
        Toast.loading({
          message: '合同加载中...',
          duration: 0
        })
        this.getNumPages()
      }
      if (signature && signature === '1') {
        this.signature = true
        this.signatureUrl = signatureUrl || ''
      }
    },
    /**
     * 获取pdf总页数
     */
    getNumPages() {
      let loadingTask = pdf.createLoadingTask({
        url: this.pdfUrl,
        //引入pdf.js字体，templ
        cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/',
        cMapPacked: true
      })
      loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages
      }).catch(() => {
        Toast.clear()
        Toast.fail({
          message: '合同加载失败'
        })
      })
    },
    /**
     * pdf加载事件
     * @param page 当前加载成功页数
     */
    handlePageLoaded(page) {
      if (page >= this.numPages) {
        Toast.clear()
      }
    },
    /**
     * 跳转签名
     */
    handleSignature() {
      if (!this.pdfUrl) {
        Toast({
          message: '合同不能为空',
          duration: 1000
        })
        return
      }
      const pdfUrl = this.initialPdfUrl && this.initialPdfUrl.length > 0 ? this.initialPdfUrl : this.pdfUrl
      this.uni.navigateTo({
        url: `/pages/common/signature?pdfUrl=${pdfUrl}`
      })
    },
    /**
     * 上传合同
     */
    handleUpload() {
      if (this.signatureUrl && this.signatureUrl.length > 0) {
        Toast({
          message: '上传成功',
          duration: 500,
          onClose: () => {
            this.uni.navigateBack()
          }
        })
      } else {
        Toast({
          message: '请先签名',
          duration: 1000
        })
      }
    }
  }
}
</script>

<style scoped>
  .viewer {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .contract-operation {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc( 45px + env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #FFC200;
    display: flex;
    align-items: center;
  }
  .operation-btn {
    width: calc(50% - 1px);
    height: 45px;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #FFC200;
    border: none;
    border-radius: 0;
  }
  .split-line {
    width: 2px;
    height: 36px;
    background-color: #FFFFFF;
    border-radius: 1px;
  }
</style>
